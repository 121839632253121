export const ComparatorWithExplicitNullHandling = function (
  valueA,
  valueB,
  nullsHigh
) {
  let out;

  if (valueA instanceof Date) {
    valueA = valueA.getTime();
  }
  if (valueB instanceof Date) {
    valueB = valueB.getTime();
  }

  if (valueA === valueB) {
    out = 0;
  } else if (valueB === null) {
    out = nullsHigh ? 1 : -1;
  } else if (valueA === null) {
    out = nullsHigh ? -1 : 1;
  } else {
    out = valueA > valueB ? 1 : -1;
  }

  return out;
};
