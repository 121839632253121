import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GetTrust from "../services/TrustService";

export default function CookiePolicy() {
  const { trust } = useParams();
  let trustDetails = GetTrust(trust);

  useEffect(() => {
    document.title = "DLP Portal | Cookie Policy";
  });
  return (
    <Box sx={{ p: 2, pb: 5 }}>
      <Typography gutterBottom variant="h2">
        Cookie Policy
      </Typography>
      <Typography gutterBottom>
        This website uses necessary cookies only, to allow the secure logging in
        of users and tracking of user sessions.
      </Typography>
    </Box>
  );
}
