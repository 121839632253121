import React from "react";
import WorkflowStatuses from "../../models/WorkflowStatus";
import SelectFormControl from "../formControls/SelectFormControl";

export default function WorkflowStatusField({
  workflowStatus,
  modalOpen,
  handleNewStatus,
}) {
  return (
    <SelectFormControl
      fullWidth={true}
      size="small"
      disabled={modalOpen}
      id="workflowStatus"
      name="workflowStatus"
      values={WorkflowStatuses()}
      value={workflowStatus}
      onChange={handleNewStatus}
      label="Workflow status"
      showSpinnerIcon={modalOpen}
    />
  );
}
