import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Button,
  Tooltip,
  Link,
  Divider,
} from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import MenuIcon from "@mui/icons-material/Menu";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import ColorSchemePicker from "./ColorSchemePicker";
import { useLocation, useParams } from "react-router-dom";

function ResponsiveAppBar() {
  const { trust, specialty } = useParams();
  let location = useLocation();

  const pages = [
    {
      text: "Appointment List",
      destination: `/${trust}/${specialty ?? "Diabetes"}`,
    },
    {
      text: "Privacy Notice",
      destination: `/${trust}/PrivacyNotice`,
      dividerBefore: true,
    },
    { text: "Cookie Policy", destination: `/${trust}/CookiePolicy` },
  ];
  const settings = [{ text: "Logout", action: () => handleLogout() }];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  //const [photoUrl, setPhotoUrl] = useState(null);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => console.log(e));
  };

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => console.log(e));
  };

  return (
    <AppBar position="static">
      <Toolbar disableGutters sx={{ pl: 3, pr: 3 }}>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {pages.map(({ text, destination, dividerBefore }) => [
              dividerBefore && <Divider sx={{ my: 0.5 }} />,
              <MenuItem
                component={Link}
                href={destination === location.pathname ? null : destination}
                onClick={
                  destination === location.pathname ? handleCloseNavMenu : null
                }
              >
                {text}
              </MenuItem>,
            ])}
          </Menu>
        </Box>
        <InsightsIcon sx={{ display: "flex", mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: "flex",
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          DLP
        </Typography>
        <Box sx={{ flexGrow: 0, mr: 2 }}>
          <ColorSchemePicker />
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {isAuthenticated ? (
            <>
              <Tooltip title={accounts[0].name}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={accounts[0].name} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={setting.action}>
                    <Typography sx={{ textAlign: "center" }}>
                      {setting.text}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Button color="inherit" onClick={() => handleLogin()}>
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
