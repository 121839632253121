import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

export default function CheckboxSelectFormControl({
  id,
  name,
  values,
  initialValue = [],
  onChange,
  label,
  error,
  helperText,
  fullWidth,
  size,
  disabled,
}) {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (event, ...args) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onChange(event, ...args);
  };

  return (
    <FormControl fullWidth={fullWidth} size={size} disabled={disabled}>
      <InputLabel id={`${id}Label`}>{label}</InputLabel>
      <Select
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        label={label}
        error={error}
        labelId={`${id}Label`}
        renderValue={(selected) => selected.join(", ")}
        multiple
      >
        {values.map((s) => (
          <MenuItem key={s.key} value={s.key}>
            <Checkbox checked={value.includes(s.key)} />
            <ListItemText primary={s.description} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}
