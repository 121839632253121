import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./scss/styles.scss";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import Logout from "./components/Logout";
import Redirecting from "./components/Redirecting";
import NothingHere from "./components/NothingHere";
import PrivacyNotice from "./components/PrivacyNotice";
import { PageLayout } from "./components/PageLayout";
import CookiePolicy from "./components/CookiePolicy";

const container = document.getElementById("root");
const root = createRoot(container);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// // Default to using the first account if no account is active on page load
// if (
//   !msalInstance.getActiveAccount() &&
//   msalInstance.getAllAccounts().length > 0
// ) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   const active = msalInstance.getAllAccounts();
//   console.log({ active });

//   msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const theme = createTheme({
  colorSchemes: {
    dark: true,
  },
});

export default function Index() {
  return (
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <BrowserRouter>
            <Routes>
              <Route path="logout" element={<Logout />} />
              <Route path="redirect" element={<Redirecting />} />
              <Route path=":trust">
                <Route index element={<NothingHere />} />
                <Route
                  path="PrivacyNotice"
                  element={
                    <PageLayout>
                      <PrivacyNotice />
                    </PageLayout>
                  }
                />
                <Route
                  path="CookiePolicy"
                  element={
                    <PageLayout>
                      <CookiePolicy />
                    </PageLayout>
                  }
                />
                <Route path=":specialty" element={<App />} />
              </Route>
              <Route path="*" element={<NothingHere />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </MsalProvider>
    </StrictMode>
  );
}

root.render(<Index />);
