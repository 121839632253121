export default function GetTrust(trustName) {
  switch (trustName) {
    case "SheffieldTeachingHospitals":
      return {
        apiShortName: "STH",
        trustName: "Sheffield Teaching Hospitals NHS Foundation Trust",
        dataController: {
          name: "Sheffield Teaching Hospitals NHS Foundation Trust",
          contactName: "Data Protection Officer",
          contactEmail: "sth.infogov@nhs.net",
          contactAddress:
            "Data Protection Officer, Department of Information Governance, Caldicott and SIRO Support, Royal Hallamshire Hospital, 2 Claremont Place, Sheffield, S10 2TB",
          icoRef: "Z8751481",
        },
      };
    default:
      return {
        apiShortName: "F50_Demo",
        trustName: "Factor 50",
        dataController: {
          name: "Factor 50",
          contactName: "Data Protection Officer",
          contactEmail: "contact@factor-50.co.uk",
          contactAddress:
            "IG Team, Factor 50, 4th Floor Market Square House, St. James’s Street, NOTTINGHAM, NG1 6FG",
          icoRef: "ZA488135",
        },
      };
  }
}
