import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PatientView from "./PatientView";
import { format } from "date-fns";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  AppBar,
  Tabs,
  Tab,
  Box,
  styled,
  ButtonBase,
  Paper,
  Button,
  useMediaQuery,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useTheme } from "@mui/material/styles";
import PatientRuleOutcomes from "./PatientRuleOutcomes";
import {
  GetRiskSegmentCssClass,
  GetRiskSegmentDescription,
} from "../../models/RiskSegment";
import AppoinmentView from "./AppointmentView";
import CloseIcon from "@mui/icons-material/Close";
import WorkflowStatusList from "./WorkflowStatusList";
import WorkflowStatusDialog from "./WorkflowStatusDialog";
import WorkflowStatusField from "./WorkflowStatusField";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const findIdentifierValueOrDefault = function (
  identifiers,
  masterIdentifierType,
  defaultValue
) {
  const identifier = identifiers.find(
    (i) => i.masterIdentifierType === masterIdentifierType
  );
  if (identifier === undefined) return defaultValue;
  return identifier.identifierValue;
};

const StyledButtonBase = styled(ButtonBase)(() => ({}));

export default function Overlay({
  registerUpdateWorkflowStatusCallback,
  appointmentDetail,
  offset,
  extractDate,
  onClose,
}) {
  const [tab, setTab] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [oldWorkflowStatus, setOldWorkflowStatus] = useState("");

  const theme = useTheme();

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    setWorkflowStatus(appointmentDetail.workflowStatus);
  }, [appointmentDetail.workflowStatus]);

  const handleNewStatus = async (event) => {
    setOldWorkflowStatus(workflowStatus);
    const newStatus = event.target.value;

    if (newStatus) {
      setWorkflowStatus(newStatus);
      setModalOpen(true);
    }
  };

  const onCancelChange = () => {
    setWorkflowStatus(oldWorkflowStatus);
    setModalOpen(false);
  };

  return (
    <>
      <Paper
        elevation={3}
        className="overlay-container"
        style={{
          "--offset-left": offset,
        }}
      >
        <div className="overlay-header">
          <div className="p-sm">
            <Button
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          </div>
        </div>
        <Box className="overlay" sx={{ pl: 3, pr: 3, pb: 3 }}>
          <List
            dense={true}
            sx={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}
          >
            <ListItem
              sx={{ alignSelf: "center", minWidth: "12rem", maxWidth: "25%" }}
            >
              <WorkflowStatusField
                workflowStatus={workflowStatus ?? ""}
                openModal={() => setModalOpen(true)}
                handleNewStatus={handleNewStatus}
              />
            </ListItem>
            <ListItem
              alignItems="flex-start"
              sx={{ minWidth: "12rem", maxWidth: "25%" }}
            >
              <StyledButtonBase
                focusRipple
                onClick={() => setTab(1)}
                sx={{ width: "100%" }}
              >
                <ListItemAvatar>
                  <Avatar
                    className={`segment-highlight ${GetRiskSegmentCssClass(
                      appointmentDetail.riskSegment
                    )}`}
                    sx={{ borderRadius: "var(--risk-segment-border-radius)" }}
                  >
                    {appointmentDetail.riskSegment}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ textAlign: "left" }}
                  primary="Overall risk"
                  secondary={GetRiskSegmentDescription(
                    appointmentDetail.riskSegment
                  )}
                />
              </StyledButtonBase>
            </ListItem>
            <ListItem
              alignItems="flex-start"
              sx={{ minWidth: "12rem", maxWidth: "25%" }}
            >
              <StyledButtonBase
                focusRipple
                onClick={() => setTab(2)}
                sx={{ width: "100%" }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <EventIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ textAlign: "left" }}
                  primary={appointmentDetail.externalClinicCode}
                  secondary={
                    appointmentDetail.appointmentStartDateObj
                      ? format(
                          appointmentDetail.appointmentStartDateObj,
                          "dd/MM/yy HH:mm"
                        )
                      : "N/A"
                  }
                />
              </StyledButtonBase>
            </ListItem>
            <ListItem
              alignItems="flex-start"
              sx={{ minWidth: "12rem", maxWidth: "25%" }}
            >
              <StyledButtonBase
                focusRipple
                onClick={() => setTab(3)}
                sx={{ width: "100%" }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ textAlign: "left" }}
                  primary={findIdentifierValueOrDefault(
                    appointmentDetail.identifiers,
                    "Full Name",
                    "Patient"
                  )}
                  secondary={findIdentifierValueOrDefault(
                    appointmentDetail.identifiers,
                    "Hospital Number",
                    findIdentifierValueOrDefault(
                      appointmentDetail.identifiers,
                      "NHS Number",
                      ""
                    )
                  )}
                />
              </StyledButtonBase>
            </ListItem>
          </List>
          <Paper elevation={2} sx={{ mt: 2 }}>
            <AppBar
              position="sticky"
              elevation={1}
              sx={(theme) => ({
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              })}
            >
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    useMediaQuery(theme.breakpoints.up("md"))
                      ? "DLP Workflow"
                      : ""
                  }
                  icon={<AccountTreeIcon />}
                  iconPosition="start"
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    useMediaQuery(theme.breakpoints.up("md"))
                      ? "Clinical Risk Factors"
                      : ""
                  }
                  icon={<ReportProblemIcon />}
                  iconPosition="start"
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    useMediaQuery(theme.breakpoints.up("md"))
                      ? "Appointment History"
                      : ""
                  }
                  icon={<EventIcon />}
                  iconPosition="start"
                  {...a11yProps(2)}
                />
                <Tab
                  label={
                    useMediaQuery(theme.breakpoints.up("md"))
                      ? "Patient Information"
                      : ""
                  }
                  icon={<PersonIcon />}
                  iconPosition="start"
                  {...a11yProps(3)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0} dir={theme.direction}>
              <WorkflowStatusList
                externalAppointmentId={appointmentDetail.externalAppointmentID}
                latestUpdateTimestamp={appointmentDetail.lastModified}
                openStatusModalCallback={() => setModalOpen(true)}
              />
            </TabPanel>
            <TabPanel value={tab} index={1} dir={theme.direction}>
              <PatientRuleOutcomes
                externalPatientID={appointmentDetail.externalPatientID}
                overallSegment={appointmentDetail.riskSegment}
              />
            </TabPanel>
            <TabPanel value={tab} index={2} dir={theme.direction}>
              <AppoinmentView
                registerUpdateWorkflowStatusCallback={
                  registerUpdateWorkflowStatusCallback
                }
                appointmentDetail={appointmentDetail}
                externalPatientId={appointmentDetail.externalPatientID}
                externalAppointmentId={appointmentDetail.externalAppointmentID}
                extractDate={extractDate}
              />
            </TabPanel>
            <TabPanel value={tab} index={3} dir={theme.direction}>
              <PatientView
                externalPatientID={appointmentDetail.externalPatientID}
              />
            </TabPanel>
          </Paper>
        </Box>
      </Paper>
      {modalOpen && (
        <WorkflowStatusDialog
          externalAppointmentId={appointmentDetail.externalAppointmentID}
          initialWorkflowStatus={workflowStatus}
          onSuccess={() => setModalOpen(false)}
          onCancel={onCancelChange}
        />
      )}
    </>
  );
}
