const riskSegments = [
  { key: "ERR", outcome: "ERROR", description: "Error", sortOrder: 1 },
  {
    key: "P1U",
    outcome: "ACUTE_URGENT",
    description: "Acute – Urgent",
    sortOrder: 2,
  },
  {
    key: "P1A",
    outcome: "ACUTE_NONURGENT",
    description: "Acute",
    sortOrder: 3,
  },
  { key: "P1", outcome: "CONCERNING", description: "Concerning", sortOrder: 4 },
  {
    key: "P2A",
    outcome: "BORDERLINE",
    description: "Borderline",
    sortOrder: 5,
  },
  { key: "P2", outcome: "AMBIGUOUS", description: "Ambiguous", sortOrder: 6 },
  {
    key: "P3",
    outcome: "NOCLASSIFICATION",
    description: "No New Data",
    sortOrder: 7,
  },
  {
    key: "P4",
    outcome: "NOTCONCERNING",
    description: "Not Concerning",
    sortOrder: 8,
  },
  { key: "P5", description: "Stable", sortOrder: 9 },
];

export default function RiskSegments() {
  return riskSegments;
}

export function GetRiskSegmentDescription(mySegment) {
  if (!mySegment) return "";
  const segment = riskSegments.find((s) => s.key === mySegment);
  return segment ? segment.description : "";
}

export function GetRiskSegmentSortOrder(mySegment) {
  if (!mySegment) return "";
  const segment = riskSegments.find((s) => s.key === mySegment);
  return segment ? segment.sortOrder : -1;
}

export function GetRiskSegmentDescriptionByOutcome(mySegment) {
  if (!mySegment) return "";
  const segment = riskSegments.find((s) => s.outcome === mySegment);
  return segment ? segment.description : "";
}

export function GetRiskSegmentCssClass(mySegment) {
  return mySegment.toLowerCase();
}

export function GetRiskSegmentCssClassByDescription(mySegmentDescription) {
  if (!mySegmentDescription) return "";
  const segment = riskSegments.find(
    (s) => s.description.toLowerCase() === mySegmentDescription.toLowerCase()
  );
  if (!segment) return "";
  return GetRiskSegmentCssClass(segment.key);
}

export function GetRiskSegmentCssClassByOutcome(mySegmentOutcome) {
  if (!mySegmentOutcome) return "";
  const segment = riskSegments.find(
    (s) => s.outcome.toLowerCase() === mySegmentOutcome.toLowerCase()
  );
  if (!segment) return "";
  return GetRiskSegmentCssClass(segment.key);
}

export function GetRiskSegmentSortOrderByOutcome(mySegmentOutcome) {
  if (!mySegmentOutcome) return -1;
  const segment = riskSegments.find((s) => s.outcome === mySegmentOutcome);
  return segment ? segment.sortOrder : -1;
}
