import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  AcquireTokenAndGet,
  AcquireTokenAndPost,
} from "./services/FetchService";
import { PageLayout } from "./components/PageLayout";
import { Box } from "@mui/material";
import * as signalR from "@microsoft/signalr";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import AppointmentGrid from "./components/AppointmentGrid";
import { loginRequest } from "./authConfig";
import {
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import UnauthorizedException from "./models/exceptions/UnauthorizedException";
import Unauthorized from "./components/Unauthorized";
import ServiceUnavailableException from "./models/exceptions/ServiceUnavailableException";
import UnavailableDialog from "./components/UnavailableDialog";
import { useParams } from "react-router-dom";

export default function App() {
  const [signalRConnection, setSignalRConnection] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [serviceIsAvailable, setserviceIsAvailable] = useState(null);
  const [appointmentsRowData, setAppointmentsRowData] = useState([]);
  const [extractDate, setExtractDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const updateWorkflowStatusCallbackRef = useRef();

  const msalContext = useMsal();
  const { instance, accounts, inProgress } = msalContext;
  const isAuthenticated = useIsAuthenticated();

  const { trust, specialty } = useParams();

  useEffect(() => {
    instance.initialize().then(() => {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      if (
        isAuthenticated &&
        isAuthorized &&
        inProgress === InteractionStatus.None
      ) {
        instance
          .acquireTokenSilent(accessTokenRequest)
          .catch(() => {
            console.warn("Error acquiring token.  Retrying...");
            return instance.acquireTokenSilent(accessTokenRequest);
          })
          .then((response) => {
            const newConnection = new signalR.HubConnectionBuilder()
              .withUrl("/api", {
                accessTokenFactory: () => response.accessToken,
              })
              .withAutomaticReconnect()
              .build();
            setSignalRConnection(newConnection);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              console.log("We need to redirect");

              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.error("Error in useEffect for signalR negotiation", error);
          });
      }
    });
  }, [inProgress, isAuthenticated, isAuthorized, accounts, instance]);

  useEffect(() => {
    // Share login/logout actions between tabs
    instance.enableAccountStorageEvents();
    instance.addEventCallback((message) => {
      console.debug("MSAL Message received", { message });
    });

    instance.addEventCallback((message) => {
      if (
        message.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
        message.interactionType === InteractionType.Silent
        //message.error?.errorCode === "monitor_window_timeout"
      ) {
        instance.acquireTokenRedirect({ ...loginRequest });
      }
    });
  }, [instance]);

  useEffect(() => {
    if (signalRConnection) {
      AcquireTokenAndPost(
        `/api/organisation/${trust}/specialty/${specialty}/subscribe`,
        {},
        msalContext
      )
        .then(() => {
          signalRConnection.start().then(() => {
            signalRConnection.on("newAppointmentWorkflowStatus", (message) => {
              updateWorkflowStatusCallbackRef.current(
                message.ExternalAppointmentID,
                message.WorkflowStatus
              );
            });
          });
        })
        .catch(
          (e) => console.error("Error subscribing to SignalR updates: ", e)
          // TODO: Show warning on-screen?
        );
    }
  }, [signalRConnection, trust, specialty, msalContext]);

  const parseDateOrNull = (dateStr) => (dateStr ? new Date(dateStr) : null);

  const fetchAppointments = useCallback(() => {
    setRequestInProgress(true);
    setLoading(true);
    AcquireTokenAndGet(
      `/api/organisation/${trust}/specialty/${specialty}/appointments`,
      msalContext
    )
      .then((response) => {
        if (loading && response !== null) {
          response.appointments.forEach((appt) => {
            appt.targetDateObj = parseDateOrNull(appt.targetDate);
            appt.lastSeenReviewDtObj = parseDateOrNull(appt.lastSeenReviewDT);
            appt.nextSeenDtObj = parseDateOrNull(appt.nextSeenDT);
            appt.pifU_ReviewDtObj = parseDateOrNull(appt.pifU_ReviewDT);
            appt.appointmentStartDateObj = parseDateOrNull(
              appt.appointmentStartDate
            );
            appt.lastModified = Date.now();
            appt.externalClinicCode = appt.externalClinicCode ?? "No Data";
          });
          setIsAuthorized(true);
          setserviceIsAvailable(true);
          setAppointmentsRowData(response.appointments);
          setExtractDate(parseDateOrNull(response.egressState.extractDate));
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error instanceof UnauthorizedException) {
          setIsAuthorized(false);
        } else if (error instanceof ServiceUnavailableException) {
          setserviceIsAvailable(false);
        } else {
          console.error("Error in useEffect for setAppointmentsRowData", error);
        }
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  }, [trust, specialty, msalContext, loading]);

  useEffect(() => {
    instance.initialize().then(() => {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        fetchAppointments();
      } else {
        setAppointmentsRowData([]);
        setLoading(true);
      }
    });
  }, [inProgress, isAuthenticated, accounts, instance]);

  function registerUpdateWorkflowStatusCallback(callback) {
    updateWorkflowStatusCallbackRef.current = callback;
  }

  return (
    <div>
      <PageLayout>
        <Box height="100%">
          <UnauthenticatedTemplate>
            <h5>
              <center>Please login to access.</center>
            </h5>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {(isAuthorized === false && (
              <Unauthorized
                user={`${accounts[0].name} (${accounts[0].username})`}
              />
            )) || (
              <>
                <AppointmentGrid
                  loading={loading}
                  appointmentsRowData={appointmentsRowData}
                  extractDate={extractDate}
                  registerUpdateWorkflowStatusCallback={
                    registerUpdateWorkflowStatusCallback
                  }
                />
                {serviceIsAvailable === false && (
                  <UnavailableDialog
                    requestInProgress={requestInProgress}
                    retryNow={() => fetchAppointments()}
                  />
                )}
              </>
            )}
          </AuthenticatedTemplate>
        </Box>
      </PageLayout>
    </div>
  );
}
