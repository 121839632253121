const GetAgGridColorScheme = (colorScheme) => {
  if (colorScheme === "light") {
    return "ag-theme-quartz";
  }
  if (colorScheme === "dark") {
    return "ag-theme-quartz-dark";
  }
  if (colorScheme === "system") {
    return "ag-theme-quartz-auto-dark";
  }
};

export default GetAgGridColorScheme;
