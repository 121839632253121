import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

export default function CopyToClipboard({ textToCopy }) {
  const [textCopied, setTextCopied] = useState(false);

  return (
    <a
      href="#"
      title={textCopied ? "Copied to clipboard" : "Copy to clipboard"}
      onClick={(event) => {
        event.preventDefault();
        navigator.clipboard.writeText(textToCopy);
        setTextCopied(true);
      }}
    >
      {(textCopied && <DoneIcon fontSize="inherit" color="success" />) || (
        <ContentCopyIcon fontSize="inherit" />
      )}
    </a>
  );
}
