import React, { useEffect, useState } from "react";
import { AcquireTokenAndGet } from "../../services/FetchService";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Stack,
} from "@mui/material";

import { format } from "date-fns";
import { GetWorkflowStatusDescription } from "../../models/WorkflowStatus";
import { GetWorkflowStatusReasonDescription } from "../../models/WorkflowStatusReason";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";

export default function WorkflowStatusList({
  externalAppointmentId,
  latestUpdateTimestamp,
  openStatusModalCallback,
}) {
  const [statusHistory, setStatusHistory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => setLoading(true), [externalAppointmentId]);

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  useEffect(() => {
    let isMounted = true;
    if (externalAppointmentId !== null) {
      AcquireTokenAndGet(
        `/api/organisation/${trust}/specialty/${specialty}/appointment/${externalAppointmentId}/workflowStatus`,
        msalContext
      )
        .then((response) => {
          if (isMounted) {
            setStatusHistory(response.workflowStatusDetails.reverse());
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error in useEffect for setStatusHistory", error);
        });
      return () => {
        isMounted = false;
      };
    }
  }, [
    externalAppointmentId,
    latestUpdateTimestamp,
    msalContext,
    trust,
    specialty,
  ]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name
        .split(" ")
        .slice(0, 2)
        .map((i) => i.charAt(0))
        .join("")
        .toUpperCase()}`,
    };
  }

  return (
    <>
      {(loading && <div>Loading...</div>) || (
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          {(statusHistory.length === 0 && (
            <Box sx={{ minWidth: "50%" }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Current workflow status
                  </Typography>
                  <Typography variant="h5">Not set</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => openStatusModalCallback()}
                  >
                    Set status
                  </Button>
                </CardActions>
              </Card>
            </Box>
          )) || (
            <Box sx={{ minWidth: "50%" }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Current workflow status
                  </Typography>
                  <Typography variant="h5">
                    {GetWorkflowStatusDescription(
                      statusHistory[0].workflowStatus
                    )}
                  </Typography>
                  <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                    Reason:{" "}
                    {GetWorkflowStatusReasonDescription(
                      statusHistory[0].workflowStatusReason
                    )}
                  </Typography>
                  <Typography variant="body2">
                    Notes: {statusHistory[0].note}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.disabled", display: "inline" }}
                  >
                    {format(statusHistory[0].timestamp, "dd/MM/yy HH:mm")} by{" "}
                    {statusHistory[0].userFullName}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => openStatusModalCallback()}
                  >
                    Update
                  </Button>
                </CardActions>
              </Card>
            </Box>
          )}
          {statusHistory.length > 1 && (
            <Box sx={{ minWidth: "50%" }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Workflow status history
                  </Typography>
                  <List>
                    {statusHistory.slice(1).map((item, index) => (
                      <ListItem alignItems="flex-start" key={index}>
                        <Tooltip title={item.userFullName} placement="left">
                          <ListItemAvatar>
                            <Avatar
                              {...stringAvatar(item.userFullName)}
                              alt={item.userFullName}
                            />
                          </ListItemAvatar>
                        </Tooltip>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              {GetWorkflowStatusDescription(
                                item.workflowStatus
                              )}
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              Reason:{" "}
                              {GetWorkflowStatusReasonDescription(
                                item.workflowStatusReason
                              )}
                              <br />
                              {item.note && (
                                <>
                                  Notes: {item.note}
                                  <br />
                                </>
                              )}
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: "text.disabled",
                                  display: "inline",
                                }}
                              >
                                {format(item.timestamp, "dd/MM/yy HH:mm")} by{" "}
                                {item.userFullName}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
}
