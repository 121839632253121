import React from "react";

const findIdentifierValueOrDefault = function (
  identifiers,
  masterIdentifierType,
  defaultValue
) {
  const identifier = identifiers.find(
    (i) => i.masterIdentifierType === masterIdentifierType
  );
  if (identifier === undefined) return defaultValue;
  return identifier.identifierValue;
};

const nameRenderer = (params) => {
  const name = findIdentifierValueOrDefault(params.value, "Full Name", null);
  const otherIdentifiers = params.value.filter(
    (i) => i.masterIdentifierType !== "Full Name"
  );

  if (otherIdentifiers.length === 0) {
    return <>{name ?? ""}</>;
  } else {
    const otherIdentifiersString = otherIdentifiers
      .map((i) => `[${i.identifierValue}]`)
      .join(" ");
    if (name !== null) {
      return (
        <div className="multi-line-cell">
          <div>{name}</div>
          <div>{otherIdentifiersString}</div>
        </div>
      );
    } else {
      return <>{otherIdentifiersString}</>;
    }
  }
};

export default nameRenderer;
