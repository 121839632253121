import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { AcquireTokenAndGet } from "../../services/FetchService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, useColorScheme, Typography } from "@mui/material";
import { GetWorkflowStatusDescription } from "../../models/WorkflowStatus";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { ComparatorWithExplicitNullHandling } from "../../models/utils/ComparatorWithExplicitNullHandling";
import { DateComparer } from "../../models/utils/DateComparer";
import FormatDate from "../../models/utils/DateFormatter";
import ServiceUnavailableException from "../../models/exceptions/ServiceUnavailableException";
import UnavailableMessage from "./UnavailableMessage";
import GetAgGridColorScheme from "../../models/utils/GetAgGridColourScheme";

export default function AppoinmentView({
  registerUpdateWorkflowStatusCallback,
  appointmentDetail,
  externalPatientId,
  externalAppointmentId,
  extractDate,
}) {
  const gridRef = useRef();

  const [appointmentsRowData, setAppointmentsRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceIsAvailable, setserviceIsAvailable] = useState(null);

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  const parseDateOrNull = (dateStr) => (dateStr ? new Date(dateStr) : null);

  registerUpdateWorkflowStatusCallback((externalAppointmentID, newStatus) => {
    const rowNode = gridRef.current.api.getRowNode(externalAppointmentID);
    if (rowNode) {
      rowNode.setDataValue("workflowStatus", newStatus);
      rowNode.setDataValue("lastModified", Date.now());
    } else {
      // Appointment for another patient
    }
  });

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    setAppointmentsRowData([]);
    if (externalPatientId !== null) {
      AcquireTokenAndGet(
        `/api/organisation/${trust}/specialty/${specialty}/patient/${externalPatientId}/appointments`,
        msalContext
      )
        .then((response) => {
          if (isMounted && response != null) {
            response.appointments.forEach((appt) => {
              appt.appointmentStartDateObj = parseDateOrNull(
                appt.appointmentStartDate
              );
              appt.targetDateObj = parseDateOrNull(appt.targetDate);
              appt.lastModified = Date.now();
              appt.externalClinicCode = appt.externalClinicCode ?? "No Data";
            });
            const appointmentsPlusTodayIndicator = response.appointments.concat(
              [
                {
                  externalAppointmentID: "(Extract date)",
                  appointmentStartDateObj: extractDate,
                  externalClinicCode: "(Date of data extraction)",
                  isTodayIndicator: true,
                },
              ]
            );
            setAppointmentsRowData(appointmentsPlusTodayIndicator);
            setserviceIsAvailable(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error instanceof ServiceUnavailableException) {
            setserviceIsAvailable(false);
            setLoading(false);
          } else {
            console.error("Error in useEffect for setStatusHistory", error);
          }
        });
      return () => {
        isMounted = false;
      };
    }
  }, [externalPatientId, extractDate, msalContext, trust, specialty]);

  const dateFilterParams = useMemo(() => {
    return {
      comparator: DateComparer,
      filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
    };
  }, []);

  const colDefs = useMemo(
    () => [
      {
        field: "appointmentStartDateObj",
        headerName: "Date",
        headerTooltip: "Appointment Date and Time",
        valueFormatter: (p) =>
          FormatDate(p.value, "SHORT", !p.data.isTodayIndicator),
        tooltipValueGetter: (p) =>
          FormatDate(p.value, "LONG", !p.data.isTodayIndicator),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        sortable: true,
        sortingOrder: ["asc", "desc"],
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (valueA === valueB) {
            // If start dates are equal, e.g. both null, show earliest target date first
            return ComparatorWithExplicitNullHandling(
              nodeA.data.targetDateObj,
              nodeB.data.targetDateObj,
              true
            );
          }
          // Show nulls first, followed by latest appointment (furthest in future, or most recent in past)
          return ComparatorWithExplicitNullHandling(valueA, valueB, false);
        },
      },
      {
        field: "externalClinicCode",
        headerName: "Clinic",
        headerTooltip: "Clinic Code",
      },
      { field: "appointmentStatus", headerTooltip: "Status" },
      {
        field: "workflowStatus",
        headerName: "DLP Workflow Status",
        headerTooltip: "DLP workflow status",
        valueFormatter: (status) => GetWorkflowStatusDescription(status.value),
      },
    ],
    [dateFilterParams]
  );

  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
      enableCellChangeFlash: true,
    };
  }, []);

  const autoSizeStrategy = {
    type: "fitCellContents",
  };

  const getRowId = useCallback((params) => {
    return params.data.externalAppointmentID;
  }, []);

  const { colorScheme } = useColorScheme();

  const getRowClass = (params) => {
    if (params.node.data.externalAppointmentID === externalAppointmentId) {
      return "row-highlight";
    } else if (params.node.data.isTodayIndicator) {
      return "row-lowlight";
    }
  };

  const sortByDate = useCallback(() => {
    gridRef.current.api.applyColumnState({
      state: [{ colId: "appointmentStartDateObj", sort: "desc" }],
      defaultState: { sort: null },
    });
  }, []);

  const onGridReady = useCallback(() => {
    sortByDate();
  }, [sortByDate]);

  return (
    <>
      <Typography variant="h5">Appointment Summary (this record)</Typography>
      <dl>
        <dt>Appointment</dt>
        <dd>
          {appointmentDetail.externalClinicCode}{" "}
          {FormatDate(appointmentDetail.appointmentStartDateObj, "SHORT", true)}{" "}
          ({appointmentDetail.appointmentStatus})
        </dd>
        <dt>Target</dt>
        <dd>
          {appointmentDetail.targetDateObj ? (
            <>
              {FormatDate(appointmentDetail.targetDateObj)} (
              {appointmentDetail.monthsUntilDue > 0 && "in "}
              {Math.abs(appointmentDetail.monthsUntilDue)}{" "}
              {appointmentDetail.monthsUntilDue === 1 ? "month" : "months"}
              {appointmentDetail.monthsUntilDue > 0 || " overdue"})
            </>
          ) : (
            ""
          )}
        </dd>
        <dt>Last review</dt>
        <dd>
          {appointmentDetail.lastSeenReviewClinic}{" "}
          {FormatDate(appointmentDetail.lastSeenReviewDtObj)}
        </dd>
        <dt>Next appointment</dt>
        <dd>
          {appointmentDetail.nextSeenClinic}{" "}
          {FormatDate(appointmentDetail.nextSeenDtObj)}
        </dd>
        <dt>PIFU review</dt>
        <dd>
          {appointmentDetail.pifU_Clinic}{" "}
          {FormatDate(appointmentDetail.pifU_ReviewDtObj)}
        </dd>
      </dl>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">
          Full Appointment History (all records)
        </Typography>
        <Box sx={{ mt: 2, height: 500 }}>
          {(serviceIsAvailable === false && <UnavailableMessage />) || (
            <AgGridReact
              className={GetAgGridColorScheme(colorScheme)}
              onGridReady={onGridReady}
              ref={gridRef}
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={appointmentsRowData}
              tooltipShowDelay={1}
              autoSizeStrategy={autoSizeStrategy}
              getRowClass={getRowClass}
              getRowId={getRowId}
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
