import { loginRequest } from "../authConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import UnauthorizedException from "../models/exceptions/UnauthorizedException";
import ServiceUnavailableException from "../models/exceptions/ServiceUnavailableException";

export function AcquireTokenAndGet(url, msalContext) {
  return AcquireTokenAndFetch("GET", url, {}, msalContext);
}

export function AcquireTokenAndPost(url, requestBody, msalContext) {
  return AcquireTokenAndFetch("POST", url, requestBody, msalContext);
}

export function AcquireTokenAndFetch(method, url, requestBody, msalContext) {
  const { instance, accounts, inProgress } = msalContext;
  const accessTokenRequest = {
    ...loginRequest,
    account: accounts[0],
  };
  return new Promise(async (resolve, reject) => {
    if (inProgress !== InteractionStatus.None) {
      throw new Error(`Cannot fetch while InteractionStatus is ${inProgress}`);
    }
    instance
      .acquireTokenSilent(accessTokenRequest)
      .catch(() => {
        console.warn("Error acquiring token.  Retrying...");
        return instance.acquireTokenSilent(accessTokenRequest);
      })
      .then((response) =>
        resolve(Fetch(method, url, requestBody, response.accessToken))
      )
      .catch((error) => {
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
          console.log("We need to redirect");

          instance.acquireTokenRedirect(accessTokenRequest);
        }
        reject(error);
      });
  });
}

export default function Fetch(
  method = "POST",
  url,
  requestBody,
  accessToken,
  retryAttempts = 2
) {
  console.log(`Making ${method} request to ${url}`);
  const bearer = `Bearer ${accessToken}`;
  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json", Authorization: bearer },
    body: method === "GET" ? null : JSON.stringify(requestBody),
  };
  return HttpFetchWithRetry(url, requestOptions, retryAttempts).then(
    (responseObj) => {
      if (!responseObj.isSuccessful) {
        throw new Error(`Response from ${url} indicates failure`);
      }

      return responseObj.content;
    }
  );
}

function HttpFetchWithRetry(url, requestOptions, retryAttempts) {
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      const message = `HTTP response status ${response.status} received from ${url}.`;
      if (response.status === 401) {
        throw new UnauthorizedException(message);
      }
      if (retryAttempts > 0) {
        console.info(`${message} Retries remaining: ${retryAttempts}`);
        return HttpFetchWithRetry(url, requestOptions, retryAttempts - 1);
      } else {
        if (response.status === 503) {
          throw new ServiceUnavailableException(message);
        }
        throw new Error(`${message} No more retries`);
      }
    }
  });
}
