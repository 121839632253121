import React, { useState } from "react";
import { AcquireTokenAndPost } from "../../services/FetchService";
import {
  TextField,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Box,
} from "@mui/material";
import WorkflowStatuses from "../../models/WorkflowStatus";
import WorkflowStatusReasons from "../../models/WorkflowStatusReason";
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectFormControl from "../formControls/SelectFormControl";
import { useParams } from "react-router-dom";

export default function WorkflowStatusDialog({
  externalAppointmentId,
  initialWorkflowStatus,
  onSuccess,
  onCancel,
}) {
  const [isSavingChange, setIsSavingChange] = useState(false);

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  const formik = useFormik({
    initialValues: {
      workflowStatus: initialWorkflowStatus,
      workflowStatusReason: "",
      note: "",
    },
    validationSchema: Yup.object({
      workflowStatus: Yup.string().required("Please select a status"),
      workflowStatusReason: Yup.string().required("Please select a reason"),
      note: Yup.string().required("Please enter a note"),
    }),
    onSubmit: (values) => {
      setIsSavingChange(true);
      AcquireTokenAndPost(
        `/api/organisation/${trust}/specialty/${specialty}/appointment/${externalAppointmentId}/status`,
        values,
        msalContext
      )
        .then((response) => {
          if (response.success) {
            onSuccess();
          } else {
            throw new Error("Failed to update workflow status");
          }
        })
        .catch((error) => {
          console.error("Error in useFormik", error);
        })
        .finally(() => {
          setIsSavingChange(false);
        });
    },
  });

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      fullWidth
      maxWidth="md"
      PaperProps={{
        component: "form",
        onSubmit: formik.handleSubmit,
        sx: { overflow: "hidden" },
      }}
    >
      <DialogTitle>Update workflow status</DialogTitle>
      <DialogContent>
        <Stack component="div" sx={{ mt: 2 }} autoComplete="off" spacing={2}>
          <SelectFormControl
            id="workflowStatus"
            name="workflowStatus"
            values={WorkflowStatuses()}
            value={formik.values.workflowStatus}
            onChange={formik.handleChange}
            label="Workflow status"
            error={
              formik.touched.workflowStatus &&
              Boolean(formik.errors.workflowStatus)
            }
            helperText={
              formik.touched.workflowStatus && formik.errors.workflowStatus
            }
            disabled={isSavingChange}
          />
          <SelectFormControl
            id="workflowStatusReason"
            name="workflowStatusReason"
            values={WorkflowStatusReasons()}
            value={formik.values.workflowStatusReason}
            onChange={formik.handleChange}
            label="Workflow status reason"
            error={
              formik.touched.workflowStatusReason &&
              Boolean(formik.errors.workflowStatusReason)
            }
            helperText={
              formik.touched.workflowStatusReason &&
              formik.errors.workflowStatusReason
            }
            disabled={isSavingChange}
          />
          <TextField
            id="note"
            name="note"
            label="Notes"
            variant="outlined"
            value={formik.values.note}
            onChange={formik.handleChange}
            error={formik.touched.note && Boolean(formik.errors.note)}
            helperText={formik.touched.note && formik.errors.note}
            disabled={isSavingChange}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSavingChange} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSavingChange} type="submit">
          Submit
        </Button>
      </DialogActions>
      {isSavingChange && (
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Dialog>
  );
}
