import React, { forwardRef } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function SelectFormControl({
  id,
  name,
  values,
  value,
  onChange,
  label,
  error,
  helperText,
  fullWidth,
  size,
  disabled,
  showSpinnerIcon,
}) {
  const CustomIcon = forwardRef((props, ref) =>
    showSpinnerIcon ? (
      <Box sx={{ display: "flex", m: 1.5 }}>
        <CircularProgress size="2rem" color="inherit" />
      </Box>
    ) : (
      <ArrowDropDownIcon {...props} ref={ref} />
    )
  );

  return (
    <FormControl fullWidth={fullWidth} size={size} disabled={disabled}>
      <InputLabel id={`${id}Label`}>{label}</InputLabel>
      <Select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        error={error}
        labelId={`${id}Label`}
        IconComponent={CustomIcon}
      >
        {values.map((s) => (
          <MenuItem key={s.key} value={s.key}>
            {s.description}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}
