import { format } from "date-fns";

const FormatDate = (
  date,
  dateFormat = "SHORT",
  includeTime = false,
  nullPlaceholder = null
) => {
  if (date > 0) {
    let formatStr;
    if (dateFormat === "SHORT") {
      formatStr = includeTime ? "dd/MM/yy HH:mm" : "dd/MM/yy";
    } else if (dateFormat === "LONG") {
      formatStr = includeTime
        ? "h:mmaaa, EEEE do MMMM yyyy"
        : "EEEE do MMMM yyyy";
    } else {
      throw new Error(`Unhandled format: ${dateFormat}`);
    }
    return format(date, formatStr);
  } else {
    return nullPlaceholder;
  }
};

export default FormatDate;
