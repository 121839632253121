import { Box, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GetTrust from "../services/TrustService";

export default function PrivacyNotice() {
  const { trust } = useParams();
  let trustDetails = GetTrust(trust);

  useEffect(() => {
    document.title = "DLP Portal | Privacy Notice";
  });
  return (
    <Box sx={{ p: 2, pb: 5 }}>
      <Typography gutterBottom variant="h2">
        Privacy Notice
      </Typography>
      <Typography gutterBottom>
        This privacy notice is for individuals creating user profiles within the
        Data Led Prioritisation (DLP) portal in order to access scored data on{" "}
        {trustDetails.trustName} patients for the purpose of direct patient
        care.
      </Typography>
      <Typography gutterBottom>
        DLP is a process by which clinical data from a variety of sources is
        used to inform decision-making about a patient&rsquo;s care.
      </Typography>
      <Typography gutterBottom>
        As part of this process we will collect personal information directly
        from you to identify you and manage your access to data within the DLP
        portal. The following sections will explain how your data has been
        collected and how it will be used by us.
      </Typography>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          The data controller
        </Typography>
        <Typography gutterBottom>
          The data controller is defined in the UK GDPR as the authority which
          determines the purposes and means of the processing of personal data.
          The Controller for your data is {trustDetails.dataController.name}.
          You may contact:
        </Typography>
        <Typography component="ul" gutterBottom>
          <Typography component="li">
            {trustDetails.dataController.contactName},{" "}
            <Link href={"mailto:" & trustDetails.dataController.contactEmail}>
              {trustDetails.dataController.contactEmail}
            </Link>
          </Typography>
          <Typography component="li">
            or write to {trustDetails.dataController.contactAddress}
          </Typography>
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          Categories of personal data
        </Typography>
        <Typography gutterBottom>
          We will capture, from you directly, the following types of personal
          data:
        </Typography>
        <Typography component="ul" gutterBottom>
          <Typography component="li">Email address</Typography>
          <Typography component="li">Name</Typography>
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          Lawful basis
        </Typography>
        <Typography gutterBottom>
          The lawful basis of processing as required by UK GDPR Article 6(1) is
          point (f) Legitimate interests: the processing is necessary for your
          legitimate interests or the legitimate interests of a third party,
          unless there is a good reason to protect the individual&rsquo;s
          personal data which overrides those legitimate interests. The
          legitimate interest is access to {trustDetails.trustName} records for
          the purpose of direct patient care through a process known as Data Led
          Prioritisation (DLP).
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          Who we will share your data with
        </Typography>
        <Typography gutterBottom>
          We will not share your data outside our organisation except for the
          third-party data processors we use to undertake necessary processing.
          We will ensure that any processor we use is compliant with the UK GDPR
          to ensure your data is handled appropriately at all times. A list of
          processors can be found below:
        </Typography>
        <Typography component="ul" gutterBottom>
          <Typography component="li">Factor 50 Ltd</Typography>
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          International transfers
        </Typography>
        <Typography gutterBottom>
          We will select processors that carry out processing within the UK, the
          European Economic Area (EEA), or any other &lsquo;adequacy&rsquo;
          country. We will not transfer your data outside of these territories.
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          How long will we keep your data
        </Typography>
        <Typography gutterBottom>
          We will keep your data for as long as you remain an active user within
          the DLP portal. Should you be inactive for a period in excess of 12
          months then your data will be deleted.
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          Your rights under the UK GDPR
        </Typography>
        <Typography gutterBottom>
          The UK GDPR gives people eight rights in relation to their own data,
          we have detailed below how these rights apply to the processing of
          your data outlined in this Privacy Notice.
        </Typography>
        <Typography component="ol" gutterBottom>
          <Typography component="li">
            <strong>Right to be informed</strong> &ndash; you have the right to
            clear and complete information about the processing of your personal
            data which is provided in this Privacy Notice
          </Typography>
          <Typography component="li">
            <strong>Right of access</strong> &ndash; you have the right to
            confirm whether your personal data is being processed by us, or on
            our behalf, and to request a copy of that data and information about
            how it is being processed
          </Typography>
          <Typography component="li">
            <strong>Right to rectification</strong> &ndash; you have the right
            to have any inaccuracies in your personal data we hold corrected
          </Typography>
          <Typography component="li">
            <strong>
              Right to erasure (&lsquo;right to be forgotten&rsquo;)
            </strong>{" "}
            &ndash; you have the right to have your personal data held by us for
            this purpose deleted, however this may not apply to other data we
            hold on you that relies on a different lawful basis for processing
          </Typography>
          <Typography component="li">
            <strong>Right to restriction of processing</strong> &ndash; you have
            the right to stop the processing of your personal data for a limited
            period of time if you believe it is inaccurate
          </Typography>
          <Typography component="li">
            <strong>Right to data portability</strong> &ndash; the UK GDPR
            provides the right to request your data be transferred from one
            controller to another controller in a commonly used machine-readable
            format in certain circumstances, however this does not apply where
            the data is collected indirectly (e.g. via a third party)
          </Typography>
          <Typography component="li">
            <strong>Right to object</strong> &ndash; you have the right to
            withdraw consent for the processing of your data as outlined in this
            Privacy Notice, however this may not apply to other data we hold on
            you that relies on a different lawful basis for processing.
          </Typography>
          <Typography component="li">
            <strong>Automated decision-making</strong> &ndash; the UK GDPR
            provides the right for human intervention in decision-making;
            automated decision making is not used for the processing outlined in
            this Privacy Notice so this right does not apply for these purposes
            of processing
          </Typography>
        </Typography>
        <Typography gutterBottom>
          If you require more information about your rights you can find out
          more{" "}
          <Link href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/">
            here
          </Link>
          , or by contacting us using the details provided at the top of this
          Privacy Notice. If you would like to exercise any of your rights you
          should contact us using the details provided at the top of this
          Privacy Notice.
        </Typography>
        <Typography gutterBottom>
          If you have contacted us and are still not happy with how we process
          your personal data you have the right to lodge a complaint with the
          Information Commissioner&rsquo;s Office (ICO); find our more{" "}
          <Link href="https://ico.org.uk/make-a-complaint/data-protection-complaints/personal-information-complaint/">
            here
          </Link>
          . Our registration reference with the ICO is{" "}
          {trustDetails.dataController.icoRef}.
        </Typography>
      </Box>

      <Box component="section" sx={{ pt: 3 }}>
        <Typography gutterBottom variant="h4">
          Where we obtained your data (third parties)
        </Typography>
        <Typography gutterBottom>
          No data is collected in addition to data captured directly.
        </Typography>
      </Box>
    </Box>
  );
}
