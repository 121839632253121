const workflowStatuses = [
  { key: "ClinicalReviewRequired", description: "Clinical Review Required" },
  { key: "ActionRequired", description: "Action Required" },
  { key: "InProgress", description: "In Progress" },
  { key: "OnHold", description: "On Hold" },
  { key: "Complete", description: "Complete" },
];

export default function WorkflowStatuses() {
  return workflowStatuses;
}

export function GetWorkflowStatusDescription(myStatus) {
  if (!myStatus) return "";
  const status = workflowStatuses.find((s) => s.key === myStatus);
  return status ? status.description : "";
}
