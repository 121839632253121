import React, { useState } from "react";
import {
  TextField,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Box,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckboxSelectFormControl from "./formControls/CheckboxSelectFormControl";
import { AcquireTokenAndPost } from "../services/FetchService";
import { BasicRoles } from "../models/Roles";
import { useParams } from "react-router-dom";

export default function AccessRequestDialog({ user, onSuccess, onCancel }) {
  const [isSavingChange, setIsSavingChange] = useState(false);

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  const formik = useFormik({
    initialValues: {
      roles: ["Read", "Write"],
      justification: "",
    },
    validationSchema: Yup.object({
      roles: Yup.array().required("Please select at least one role"),
      justification: Yup.string().required(
        "Please enter a brief justification for your request"
      ),
    }),
    onSubmit: (values) => {
      setIsSavingChange(true);

      const submitRequest = (role) => {
        return new Promise((resolve, reject) => {
          AcquireTokenAndPost(
            `/api/organisation/${trust}/specialty/${specialty}/role/${role}/accessrequest`,
            {
              justification: values.justification,
            },
            msalContext
          )
            .then((success) => {
              if (success) {
                resolve();
              } else {
                reject(`Failed to submit access request for role ${role}.`);
              }
            })
            .catch((error) => {
              console.error("Error in useFormik", error);
              reject(error);
            });
        });
      };

      var actions = values.roles.map(submitRequest);

      Promise.all(actions)
        .then(() => onSuccess())
        .catch((error) => {
          console.error(
            "At least one access request cold not be submitted.",
            error
          );
        })
        .finally(() => {
          setIsSavingChange(false);
        });
    },
  });

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      fullWidth
      maxWidth="md"
      PaperProps={{
        component: "form",
        onSubmit: formik.handleSubmit,
        sx: { overflow: "hidden" },
      }}
    >
      <DialogTitle>Request access</DialogTitle>
      <DialogContent>
        <Stack component="div" sx={{ mt: 2 }} autoComplete="off" spacing={2}>
          <TextField
            id="organisation"
            name="organisation"
            label="Organisation"
            variant="outlined"
            value={trust}
            disabled={true}
          />
          <TextField
            id="specialty"
            name="specialty"
            label="Specialty"
            variant="outlined"
            value={specialty}
            disabled={true}
          />
          <TextField
            id="user"
            name="user"
            label="User"
            variant="outlined"
            value={user}
            disabled={true}
          />
          <CheckboxSelectFormControl
            id="roles"
            name="roles"
            values={BasicRoles()}
            value={formik.values.roles}
            initialValue={["Read", "Write"]}
            onChange={formik.handleChange}
            label="Role(s)"
            error={formik.touched.roles && Boolean(formik.errors.roles)}
            helperText={formik.touched.roles && formik.errors.roles}
            disabled={true}
          />
          <TextField
            id="justification"
            name="justification"
            label="Justification"
            variant="outlined"
            value={formik.values.justification}
            onChange={formik.handleChange}
            error={
              formik.touched.justification &&
              Boolean(formik.errors.justification)
            }
            helperText={
              formik.touched.justification && formik.errors.justification
            }
            disabled={isSavingChange}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSavingChange} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSavingChange} type="submit">
          Submit
        </Button>
      </DialogActions>
      {isSavingChange && (
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Dialog>
  );
}
