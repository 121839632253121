const roles = [
  { key: "Read", description: "Read (view information)", type: "Basic" },
  {
    key: "Write",
    description: "Write (make changes, e.g. to worklow status)",
    type: "Basic",
  },
  {
    key: "Admin",
    description: "Admin (approve access requests for others)",
    type: "Privileged",
  },
];

export default function Roles() {
  return roles;
}

export function BasicRoles() {
  return roles.filter((role) => role.type === "Basic");
}

export function GetRoleDescription(myRole) {
  if (!myRole) return "";
  const role = roles.find((s) => s.key === myRole);
  return role ? role.description : "";
}
