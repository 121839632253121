const workflowStatusReasons = [
  { key: "NoActionWatch", description: "No action (watch)" },
  { key: "Invite", description: "Invite" },
  { key: "Book", description: "Book" },
  {
    key: "AppointmentAlreadyBooked",
    description: "Appointment already booked",
  },
  { key: "PIFUToCR", description: "PIFU to CR" },
  { key: "Discharge", description: "Discharge" },
  { key: "TaskedAsNonResponder", description: "Tasked as non-responder" },
  { key: "ActionInProgress", description: "Action in progress" },
  { key: "HardToReach", description: "Hard to reach" },
  { key: "Vulnerable", description: "Vulnerable" },
  { key: "PreConception", description: "Pre-conception" },
  { key: "Query", description: "Query" },
  { key: "Other", description: "Other" },
];

export default function WorkflowStatusReasons() {
  return workflowStatusReasons;
}

export function GetWorkflowStatusReasonDescription(myStatusReason) {
  if (!myStatusReason) return "";
  const statusReason = workflowStatusReasons.find(
    (s) => s.key === myStatusReason
  );
  return statusReason ? statusReason.description : "";
}
