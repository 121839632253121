import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { AcquireTokenAndGet } from "../../services/FetchService";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import ServiceUnavailableException from "../../models/exceptions/ServiceUnavailableException";
import UnavailableMessage from "./UnavailableMessage";

export default function PatientView({ externalPatientID }) {
  const [patientDemographics, setPatientDemographics] = useState({});
  const [loading, setLoading] = useState(true);
  const [serviceIsAvailable, setserviceIsAvailable] = useState(null);

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  useEffect(() => {
    console.log({ externalPatientID });
    let isMounted = true;
    setLoading(true);
    if (externalPatientID !== null) {
      AcquireTokenAndGet(
        `/api/organisation/${trust}/specialty/${specialty}/patient/${externalPatientID}`,
        msalContext
      )
        .then((response) => {
          if (isMounted) {
            setPatientDemographics(response.patient);
            setserviceIsAvailable(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error instanceof ServiceUnavailableException) {
            setserviceIsAvailable(false);
            setLoading(false);
          } else {
            console.error(
              "Error in useEffect for setPatientDemographics",
              error
            );
          }
        });

      return () => {
        isMounted = false;
      };
    }
  }, [externalPatientID, msalContext, trust, specialty]);

  return (
    (loading && <div>Loading...</div>) ||
    (serviceIsAvailable === false && <UnavailableMessage />) || (
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Box sx={{ minWidth: "50%" }}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                Personal information
              </Typography>
              <dl>
                {patientDemographics.identifiers.map((identifier) => (
                  <>
                    <dt>{identifier.identifierType}</dt>
                    <dd>{identifier.identifierValue}</dd>
                  </>
                ))}
              </dl>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: "50%" }}>
          <Card variant="outlined">
            <CardContent>
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                Demographic information
              </Typography>
              <dl>
                <dt>Age</dt>
                <dd>{patientDemographics.age}</dd>
                <dt>Ethnicity</dt>
                <dd>{patientDemographics.ethnicity}</dd>
                <dt>Gender</dt>
                <dd>{patientDemographics.gender}</dd>
                <dt>IMDD</dt>
                <dd>{patientDemographics.imdd}</dd>
              </dl>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    )
  );
}
