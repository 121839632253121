import React, { useEffect, useState, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  useColorScheme,
  Typography,
  Box,
} from "@mui/material";
import {
  GetRiskSegmentCssClass,
  GetRiskSegmentCssClassByDescription,
  GetRiskSegmentCssClassByOutcome,
  GetRiskSegmentDescription,
  GetRiskSegmentDescriptionByOutcome,
  GetRiskSegmentSortOrderByOutcome,
} from "../../models/RiskSegment";
import { AcquireTokenAndGet } from "../../services/FetchService";
import { useMsal } from "@azure/msal-react";
import { GetRuleDescription } from "../../models/Rules";
import { useParams } from "react-router-dom";
import wrapTextCellRenderer from "../cellRenderers/wrapTextCellRenderer";
import ServiceUnavailableException from "../../models/exceptions/ServiceUnavailableException";
import UnavailableMessage from "./UnavailableMessage";
import GetAgGridColorScheme from "../../models/utils/GetAgGridColourScheme";
import CopyToClipboard from "./CopyToClipboard";

export default function PatientRuleOutcomes({
  externalPatientID,
  overallSegment,
}) {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summaryFactors, setSummaryFactors] = useState([]);
  const [serviceIsAvailable, setserviceIsAvailable] = useState(null);
  const [summaryText, setSummaryText] = useState(null);

  const gridRef = useRef();

  const colDefs = useMemo(
    () => [
      {
        field: "friendlyName",
        headerName: "Criterion",
      },
      {
        field: "outcome",
        cellClass: ({ value }) => [
          "segment-highlight",
          GetRiskSegmentCssClassByOutcome(value),
        ],
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          return (
            GetRiskSegmentSortOrderByOutcome(valueA) -
            GetRiskSegmentSortOrderByOutcome(valueB)
          );
        },
        valueFormatter: (p) => GetRiskSegmentDescriptionByOutcome(p.value),
      },
      {
        field: "description",
        valueFormatter: ({ node }) =>
          GetRuleDescription(node.data.friendlyName),
        cellRenderer: wrapTextCellRenderer,
      },
    ],
    []
  );

  const msalContext = useMsal();

  const { trust, specialty } = useParams();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    if (externalPatientID !== null) {
      AcquireTokenAndGet(
        `/api/organisation/${trust}/specialty/${specialty}/patient/${externalPatientID}/rules`,
        msalContext
      )
        .then((response) => {
          if (isMounted) {
            setRowData(response.rules);
            setSummaryFactors([
              {
                outcome: "Acute – Urgent",
                number: response.ruleSummary.acuteUrgentCount,
              },
              {
                outcome: "Acute",
                number: response.ruleSummary.acuteNonUrgentCount,
              },
              {
                outcome: "Concerning",
                number: response.ruleSummary.concerningCount,
              },
              {
                outcome: "Borderline",
                number: response.ruleSummary.borderlineCount,
              },
              {
                outcome: "Ambiguous",
                number: response.ruleSummary.ambiguousCount,
              },
              {
                outcome: "No new data",
                number: response.ruleSummary.noClassificationCount,
              },
              {
                outcome: "Not concerning",
                number: response.ruleSummary.notConcerningCount,
              },
            ]);
            setSummaryText(response.ruleSummary.ruleOutcomeSummaryText);
            setserviceIsAvailable(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error instanceof ServiceUnavailableException) {
            setserviceIsAvailable(false);
            setLoading(false);
          } else {
            console.error("Error in useEffect for setSummaryFactors", error);
          }
        });
      return () => {
        isMounted = false;
      };
    }
  }, [externalPatientID, trust, specialty, msalContext]);

  const autoSizeStrategy = {
    type: "fitCellContents",
  };

  const { colorScheme } = useColorScheme();

  return (
    <>
      <Typography variant="h5">Risk summary</Typography>
      <List
        dense={true}
        sx={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap" }}
      >
        <ListItem
          key="overall"
          alignItems="flex-start"
          sx={{ maxWidth: "13rem" }}
        >
          <ListItemAvatar>
            <Avatar
              className={`segment-highlight ${GetRiskSegmentCssClass(
                overallSegment
              )}`}
              sx={{
                borderRadius: "var(--risk-segment-border-radius)",
                borderStyle: "solid",
                borderColor: "text.primary",
                borderWidth: "2px",
              }}
            >
              {overallSegment}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={GetRiskSegmentDescription(overallSegment)}
            secondary="Overall risk"
            primaryTypographyProps={{ fontWeight: "bold" }}
            secondaryTypographyProps={{ fontWeight: "bold" }}
          />
        </ListItem>
        {loading ||
          summaryFactors.map((f) => (
            <ListItem
              key={f.outcome}
              alignItems="flex-start"
              sx={{ maxWidth: "13rem" }}
            >
              <ListItemAvatar>
                <Avatar
                  className={`segment-highlight ${GetRiskSegmentCssClassByDescription(
                    f.outcome
                  )}`}
                  sx={{
                    borderRadius: "var(--risk-segment-border-radius)",
                    fontWeight: "normal",
                  }}
                >
                  {f.number}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${f.outcome}`} secondary={"# factors"} />
            </ListItem>
          ))}
      </List>
      {loading || (
        <Typography>
          {summaryText} <CopyToClipboard textToCopy={summaryText} />
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5">Detailed risk criteria</Typography>
        <Box sx={{ mt: 2, height: 500 }}>
          {(loading && <div>Loading...</div>) ||
            (serviceIsAvailable === false && <UnavailableMessage />) || (
              <AgGridReact
                className={GetAgGridColorScheme(colorScheme)}
                gridRef={gridRef}
                columnDefs={colDefs}
                rowData={rowData}
                tooltipShowDelay={1}
                autoSizeStrategy={autoSizeStrategy}
              />
            )}
        </Box>
      </Box>
    </>
  );
}
