import { format } from "date-fns";

export const DateComparer = (filterLocalDateAtMidnight, cellDate) => {
  if (
    format(filterLocalDateAtMidnight, "dd/MM/yy") ===
    format(cellDate.getTime(), "dd/MM/yy")
  ) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};
