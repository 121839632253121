import React, { useState } from "react";
import AccessRequestDialog from "./AccessRequestDialog";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

export default function Unauthorized({ user }) {
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={2}>
        {requestSubmitted && (
          <Alert severity="success">
            <AlertTitle>Request submitted</AlertTitle>Your access request has
            been successfully submitted.
          </Alert>
        )}
        <Box display="flex" justifyContent="center">
          <Paper elevation={3} sx={{ maxWidth: "md", p: 2, m: 2 }}>
            <Typography variant="h3" gutterBottom>
              Welcome to the DLP Portal
            </Typography>
            <Typography variant="body1" gutterBottom>
              Thanks for logging in. In order to see your service’s data, please
              submit an access request by pressing the button below.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your request will then be reviewed by Factor 50, and your access
              should be granted in a few days.
            </Typography>
            <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={() => setDialogVisible(true)}
                disabled={requestSubmitted}
              >
                Request access
              </Button>
            </Box>
          </Paper>
        </Box>
      </Stack>
      {dialogVisible && (
        <AccessRequestDialog
          user={user}
          onSuccess={() => {
            setDialogVisible(false);
            setRequestSubmitted(true);
          }}
          onCancel={() => setDialogVisible(false)}
        />
      )}
    </>
  );
}
