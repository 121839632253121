import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useColorScheme } from "@mui/material/styles";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

function ColorSchemePicker() {
  const { colorScheme, setColorScheme } = useColorScheme();

  const handleColorSchemeToggle = (event, newColorScheme) => {
    setColorScheme(newColorScheme);
  };

  if (!colorScheme) {
    return null;
  }
  return (
    <>
      <ToggleButtonGroup
        value={colorScheme}
        exclusive
        onChange={handleColorSchemeToggle}
        aria-label="display theme"
        size="small"
      >
        <ToggleButton value="light" aria-label="light theme">
          <LightModeIcon fontSize="small" />
        </ToggleButton>
        <ToggleButton value="dark" aria-label="dark theme">
          <DarkModeIcon fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}

export default ColorSchemePicker;
