import React from "react";
import ResponsiveAppBar from "./header/ResponsiveAppBar";
import { Box } from "@mui/material";

export const PageLayout = (props) => {
  return (
    <>
      <ResponsiveAppBar />
      <Box className="site-content" sx={{ p: 2 }}>
        {props.children}
      </Box>
    </>
  );
};
