import { Alert, AlertTitle } from "@mui/material";

export default function UnavailableMessage() {
  return (
    <Alert severity="warning">
      <AlertTitle>
        Sorry, we're unable to display this information at present.
      </AlertTitle>
      Please try again shortly.
    </Alert>
  );
}
