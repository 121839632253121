import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Box,
  DialogContentText,
} from "@mui/material";

export default function UnavailableDialog({
  requestInProgress,
  retryNow,
  onCancel,
}) {
  const [progress, setProgress] = useState(100);
  const intervalRef = useRef(undefined);

  useEffect(() => {
    if (requestInProgress) {
      clearInterval(intervalRef.current);
    } else {
      setProgress(100);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 0) {
            retryNow();
            clearInterval(intervalRef.current);
            return 0;
          }
          return oldProgress - 5;
        });
      }, 1000);
      intervalRef.current = timer;

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [requestInProgress, retryNow]);

  return (
    <Dialog open={true} onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle>Service temporarily unavailable</DialogTitle>
      <DialogContent dividers>
        <DialogContentText gutterBottom>
          Sorry, the service is temporarily unavailable. This usually means
          we're loading in fresh data.
        </DialogContentText>
        {(requestInProgress && (
          <DialogContentText gutterBottom>Retrying now...</DialogContentText>
        )) || (
          <DialogContentText gutterBottom>
            We'll automatically retry in {progress / 5}{" "}
            {progress / 5 === 1 ? "second" : "seconds"}.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={requestInProgress} onClick={retryNow}>
          Retry now
        </Button>
      </DialogActions>
      <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <LinearProgress
          variant={requestInProgress ? "indeterminate" : "determinate"}
          value={progress}
        />
      </Box>
    </Dialog>
  );
}
