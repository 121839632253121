const rules = [
  {
    key: "Diabetes Related A&E Visits",
    description:
      "An assessment of whether the patient has presented at A&E for any diabetes related reason since they were last reviewed.",
  },
  {
    key: "Decrease in eGFR",
    description:
      "An assessment of any change in eGFR result(s) recorded since the patient was last reviewed, and which might be evidence of decreasing kidney function.",
  },
  {
    key: "Diabetic Eye Disease Treatments",
    description:
      "An assessment of whether the patient has been undergoing any diabetic eye disease treatments since they were last reviewed.",
  },
  {
    key: "Decrease in HbA1c",
    description:
      "An assessment of any decline in HbA1c result(s) recorded since the patient was last reviewed, and which might be indicative of a decrease in their blood sugar over time. Note: HbA1c results are not considered when sufficient CGM data is available.",
  },
  {
    key: "Increase in HbA1c",
    description:
      "An assessment of any rise in HbA1c result(s) recorded since the patient was last reviewed, and which might be indicative of an increase in their blood sugar over time. Note: HbA1c results are not considered when sufficient CGM data is available.",
  },
  {
    key: "CGM TBR (Time Below Range)",
    description:
      "An assessment of the proportion of time the patient has spent below the target range in the past 14 days. There must be sufficient device reporting coverage (at least 70%) over this period for data to be considered.",
  },
  {
    key: "CGM GMI (Glucose Management Indicator)",
    description:
      "An assessment of the patient's Glucose Management Index (GMI) measured over the past 14 days. There must be sufficient device reporting coverage (at least 70%) over this period for data to be considered.",
  },
  {
    key: "High HbA1c",
    description:
      "An assessment of the highest new HbA1c result recorded since the patient was last reviewed, which might be indicative of high blood sugar. Note: HbA1c results are not considered when sufficient CGM data is available.",
  },
  {
    key: "Low HbA1c",
    description:
      "An assessment of the lowest new HbA1c result recorded since the patient was last reviewed, which might be indicative of low blood sugar. Note: HbA1c results are not considered when sufficient CGM data is available.",
  },
];

export default function Rules() {
  return rules;
}

export function GetRuleDescription(myRule) {
  if (!myRule) return "";
  const rule = rules.find((s) => s.key === myRule);
  return rule ? rule.description : "";
}
