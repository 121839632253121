import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GetWorkflowStatusDescription } from "../models/WorkflowStatus";
import {
  GetRiskSegmentDescription,
  GetRiskSegmentSortOrder,
} from "../models/RiskSegment";
import {
  Box,
  useColorScheme,
  Fab,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import Overlay from "./overlay/Overlay";
import nameRenderer from "./cellRenderers/nameRenderer";
import { ComparatorWithExplicitNullHandling } from "../models/utils/ComparatorWithExplicitNullHandling";
import { DateComparer } from "../models/utils/DateComparer";
import FormatDate from "../models/utils/DateFormatter";
import GetAgGridColorScheme from "../models/utils/GetAgGridColourScheme";

export default function AppointmentGrid({
  registerUpdateWorkflowStatusCallback,
  appointmentsRowData,
  extractDate,
  loading,
}) {
  const gridRef = useRef();

  const [overlayOffset, setOverlayOffset] = useState("70%");
  const [selectedAppointmentNode, setSelectedAppointmentNode] = useState(null);

  // Changes to state cause this component and child components to re-render
  // eslint-disable-next-line no-unused-vars
  const [selectedAppointmentModified, setSelectedAppointmentModified] =
    useState(null);

  const [defaultSortActive, setDefaultSortActive] = useState(true);
  const [dataRecencyAlertVisible, setDataRecencyAlertVisible] = useState(false);
  const [dataRecencyAlertSeverity, setDataRecencyAlertSeverity] = useState("");
  const [daysSinceUpdate, setDaysSinceUpdate] = useState(0);

  const updateWorkflowStatusInOverlayCallbackRef = useRef();

  function registerUpdateWorkflowStatusInOverlayCallback(callback) {
    updateWorkflowStatusInOverlayCallbackRef.current = callback;
  }

  const sortByRiskAsc = useCallback(() => {
    gridRef.current.api.applyColumnState({
      state: [
        { colId: "riskSegmentForSort", sort: "asc" },
        { colId: "outcomeCountSortIndex", sort: "desc" },
        { colId: "externalPatientID", sort: "asc" },
        { colId: "targetDateObj", sort: "asc" },
        { colId: "externalAppointmentID", sort: "asc" },
      ],
      defaultState: { sort: null },
    });
  }, []);

  const onCloseOverlay = useCallback((params) => {
    gridRef.current.api.deselectAll();
  }, []);

  const onGridReady = useCallback(() => {
    sortByRiskAsc();
  }, [sortByRiskAsc]);

  registerUpdateWorkflowStatusCallback((externalAppointmentID, newStatus) => {
    const rowNode = gridRef.current.api.getRowNode(externalAppointmentID);
    if (rowNode) {
      rowNode.setDataValue("workflowStatus", newStatus);
      rowNode.setDataValue("lastModified", Date.now());
      if (
        selectedAppointmentNode !== null &&
        selectedAppointmentNode.id === externalAppointmentID
      ) {
        setSelectedAppointmentModified(rowNode.data.lastModified);
      }
    } else {
      console.error(`Unable to locate row ${externalAppointmentID}`);
    }
    updateWorkflowStatusInOverlayCallbackRef.current(
      externalAppointmentID,
      newStatus
    );
  });

  const onRowClicked = useCallback(
    (params) => {
      if (
        selectedAppointmentNode !== null &&
        selectedAppointmentNode.id === params.node.id
      ) {
        gridRef.current.api.deselectAll();
      }
    },
    [selectedAppointmentNode]
  );

  const onFilterModified = useCallback(
    // Update grid filtering before Apply is pressed
    (params) => {
      // Apply the model to ensure any changes in the UI or via API methods are recognised
      params.filterInstance.applyModel();

      // Tell grid to run filter operation again
      gridRef.current.api.onFilterChanged();
    },
    []
  );

  const updateOverlayOffset = useCallback(() => {
    const els = document.getElementsByClassName(
      "ag-pinned-left-cols-container"
    );
    const elemRect = els[0].getBoundingClientRect();
    setOverlayOffset(elemRect.right + "px");
  }, []);

  const onColumnResized = useCallback(
    (params) => {
      if (params.finished) {
        updateOverlayOffset();
      }
    },
    [updateOverlayOffset]
  );

  useEffect(() => {
    if (extractDate !== null) {
      const daysSinceExtractDate = Math.floor(
        (new Date() - extractDate) / (1000 * 60 * 60 * 24)
      );
      setDaysSinceUpdate(daysSinceExtractDate);
    }
  }, [extractDate]);

  useEffect(() => {
    if (daysSinceUpdate > 14) {
      setDataRecencyAlertSeverity("error");
      setDataRecencyAlertVisible(true);
    } else if (daysSinceUpdate > 7) {
      setDataRecencyAlertSeverity("warning");
      setDataRecencyAlertVisible(true);
    } else {
      setDataRecencyAlertSeverity("info");
      setDataRecencyAlertVisible(false);
    }
  }, [daysSinceUpdate]);

  const onSortChanged = useCallback((params) => {
    const { api } = params;
    const colState = api.getColumnState();
    const sortSummary = colState
      .filter((c) => c.sort !== null)
      .map((c) => `${c.colId} ${c.sort}`)
      .join(", ");

    setDefaultSortActive(
      sortSummary ===
        "riskSegmentForSort asc, outcomeCountSortIndex desc, externalPatientID asc, targetDateObj asc, externalAppointmentID asc"
    );
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      setSelectedAppointmentNode(null);
    } else if (selectedNodes.length === 1) {
      setSelectedAppointmentNode(selectedNodes[0]);
      setSelectedAppointmentModified(selectedNodes[0].data.lastModified);
      updateOverlayOffset();
    } else {
      throw new Error("Too many rows");
    }
  }, [updateOverlayOffset]);

  const dateFilterParams = useMemo(() => {
    return {
      comparator: DateComparer,
      filterOptions: [
        "equals",
        "lessThan",
        "greaterThan",
        "inRange",
        "blank",
        "notBlank",
      ],
    };
  }, []);

  const rowClassRules = {
    overdue: (params) => {
      return params.data.monthsUntilDue <= 0;
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      filterParams: {
        buttons: ["clear", "apply"],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      wrapHeaderText: true,
      enableCellChangeFlash: true,
      sortingOrder: ["asc", "desc"],
    };
  }, []);

  const defaultColGroupDef = useMemo(() => {
    return {
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const autoSizeStrategy = {
    type: "fitCellContents",
    colIds: [
      "externalPatientID",
      "externalClinicCode",
      "appointmentStatus",
      "appointmentStartDate",
      "targetDate",
      "monthsUntilDue",
      "lastSeenReviewDt",
      "lastSeenReviewClinic",
      "nextSeenDt",
      "nextSeenClinic",
      "pifU_ReviewDt",
      "pifU_Clinic",
    ],
  };

  const navigateToNextCell = (params) => {
    const suggestedNextCell = params.nextCellPosition;

    const KEY_UP = "ArrowUp";
    const KEY_DOWN = "ArrowDown";

    const noUpOrDownKey = params.key !== KEY_DOWN && params.key !== KEY_UP;
    if (noUpOrDownKey) {
      return suggestedNextCell;
    }

    if (suggestedNextCell != null) {
      const nodeToSelect = params.api.getDisplayedRowAtIndex(
        suggestedNextCell.rowIndex
      );
      if (nodeToSelect) {
        nodeToSelect.setSelected(true);
      }
    }

    return suggestedNextCell;
  };

  const colDefs = useMemo(
    () => [
      {
        field: "riskSegment",
        headerName: "",
        headerTooltip: "DLP Risk Segment",
        cellClass: ({ value }) => ["segment-highlight", value.toLowerCase()],
        pinned: "left",
        comparator: (valueA, valueB) =>
          GetRiskSegmentSortOrder(valueA) - GetRiskSegmentSortOrder(valueB),
        tooltipValueGetter: (p) => GetRiskSegmentDescription(p.value),
        width: 75,
        filterParams: {
          filterOptions: ["equals"],
        },
      },
      {
        headerName: "defaultSortFields",
        // Duplicating fields here, to avoid unexpected behaviour when clicking on a column header included in default sort
        children: [
          {
            colId: "riskSegmentForSort",
            field: "riskSegment",
            comparator: (valueA, valueB) =>
              GetRiskSegmentSortOrder(valueA) - GetRiskSegmentSortOrder(valueB),
          },
          { field: "outcomeCountSortIndex" },
          { field: "externalPatientID" },
          {
            field: "targetDateObj",
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, true),
          },
          { field: "externalAppointmentID" },
        ].map((col) => ({ ...col, hide: true, lockVisible: true })),
      },
      {
        field: "identifiers",
        headerName: "Patient",
        headerTooltip: "Patient Details",
        cellRenderer: nameRenderer,
        valueFormatter: (identifiers) =>
          identifiers.value.map((i) => i.identifierValue).join(" "),
        filterParams: {
          filterOptions: ["contains"],
        },
        pinned: "left",
      },
      {
        field: "workflowStatus",
        headerName: "DLP Workflow Status",
        headerTooltip: "DLP workflow status",
        valueFormatter: (status) => GetWorkflowStatusDescription(status.value),
        pinned: "left",
        width: 120,
        comparator: (valueA, valueB) =>
          ComparatorWithExplicitNullHandling(valueA, valueB, false),
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "startsWith",
            "blank",
            "notBlank",
          ],
        },
      },
      {
        headerName: "Appointment or Waitlist Record",
        children: [
          {
            field: "externalClinicCode",
            headerName: "Clinic",
            headerTooltip: "Clinic Code",
            filterParams: {
              filterOptions: [
                "contains",
                "notContains",
                "equals",
                "notEqual",
                "startsWith",
                "endsWith",
              ],
            },
          },
          {
            field: "appointmentStatus",
            headerName: "Status",
            headerTooltip: "Appointment Status",
          },
          {
            colId: "appointmentStartDate",
            field: "appointmentStartDateObj",
            headerName: "Date",
            headerTooltip: "Appointment Date and Time",
            cellDataType: "date",
            valueFormatter: (p) => FormatDate(p.value, "SHORT", true),
            tooltipValueGetter: (p) =>
              FormatDate(p.value, "LONG", true, "Unknown, or not yet booked"),
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, false),
          },
        ],
      },
      {
        headerName: "Clinical Target Date",
        children: [
          {
            colId: "targetDate",
            field: "targetDateObj",
            headerName: "Date",
            headerTooltip: "Clinical Target Date",
            cellDataType: "date",
            valueFormatter: (p) => FormatDate(p.value, "SHORT"),
            tooltipValueGetter: (p) => FormatDate(p.value, "LONG"),
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, true),
            sortingOrder: ["asc", "desc"],
            cellClass: "highlight-overdue add-left-border",
          },
          {
            field: "monthsUntilDue",
            headerName: "Months",
            headerTooltip: "Months Until Due",
            tooltipValueGetter: (p) => {
              if (p.value < 0) {
                return `${p.value * -1} ${
                  p.value === -1 ? "month" : "months"
                } overdue`;
              } else {
                return `Due in ${p.value} ${
                  p.value === 1 ? "month" : "months"
                }`;
              }
            },
            comparator: (valueA, valueB, nodeA, nodeB) =>
              ComparatorWithExplicitNullHandling(
                nodeA.data.targetDateObj,
                nodeB.data.targetDateObj,
                true
              ),
            sortingOrder: ["asc", "desc"],
            cellClass: "highlight-overdue",
          },
        ],
      },
      {
        headerName: "Last Seen in Review Clinic",
        children: [
          {
            colId: "lastSeenReviewDt",
            field: "lastSeenReviewDtObj",
            headerName: "Date",
            headerTooltip: "Date Last Seen In Review Clinic",
            cellDataType: "date",
            valueFormatter: (p) => FormatDate(p.value, "SHORT"),
            tooltipValueGetter: (p) => FormatDate(p.value, "LONG"),
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, false),
            cellClass: "add-left-border",
          },
          {
            field: "lastSeenReviewClinic",
            headerName: "Clinic",
            headerTooltip: "Review Clinic where last seen",
          },
        ],
      },
      {
        headerName: "Next Appointment",
        children: [
          {
            colId: "nextSeenDt",
            field: "nextSeenDtObj",
            headerName: "Date",
            headerTooltip: "Next Appointment Date",
            cellDataType: "date",
            valueFormatter: (p) => FormatDate(p.value, "SHORT"),
            tooltipValueGetter: (p) => FormatDate(p.value, "LONG"),
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, false),
            cellClass: "add-left-border",
          },
          {
            field: "nextSeenClinic",
            headerName: "Clinic",
            headerTooltip: "Next Appointment Clinic",
          },
        ],
      },
      {
        headerName: "PIFU Review",
        headerTooltip: "Patient initiated follow-up review",
        children: [
          {
            colId: "pifU_ReviewDt",
            field: "pifU_ReviewDtObj",
            headerName: "Date",
            headerTooltip: "PIFU: Next Review Date",
            cellDataType: "date",
            valueFormatter: (p) => FormatDate(p.value, "SHORT"),
            tooltipValueGetter: (p) => FormatDate(p.value, "LONG"),
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            comparator: (valueA, valueB) =>
              ComparatorWithExplicitNullHandling(valueA, valueB, false),
            cellClass: "add-left-border",
          },
          {
            field: "pifU_Clinic",
            headerTooltip: "PIFU: Access Plan with soonest review date",
            headerName: "Plan",
          },
        ],
      },
      {
        field: "lastModified",
        hide: true,
        lockVisible: true,
      },
    ],
    [dateFilterParams]
  );

  const getRowId = useCallback((params) => {
    return params.data.externalAppointmentID;
  }, []);

  const { colorScheme } = useColorScheme();

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        className={`clickable-rows ${
          defaultSortActive ? "default-sort-active" : ""
        }`}
      >
        <Box flex={1}>
          <AgGridReact
            className={GetAgGridColorScheme(colorScheme)}
            ref={gridRef}
            loading={loading}
            onGridReady={onGridReady}
            rowData={appointmentsRowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            defaultColGroupDef={defaultColGroupDef}
            rowClassRules={rowClassRules}
            pagination={true}
            paginationAutoPageSize={true}
            tooltipShowDelay={1}
            autoSizeStrategy={autoSizeStrategy}
            rowSelection={"single"}
            onSelectionChanged={onSelectionChanged}
            onRowClicked={onRowClicked}
            onFilterModified={onFilterModified}
            navigateToNextCell={navigateToNextCell}
            onColumnResized={onColumnResized}
            suppressColumnVirtualisation={true}
            getRowId={getRowId}
            onSortChanged={onSortChanged}
          />
        </Box>
      </Box>

      {loading || (
        <>
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={dataRecencyAlertVisible}
          >
            <Alert
              severity={dataRecencyAlertSeverity}
              variant="filled"
              onClose={() => {
                setDataRecencyAlertVisible(false);
              }}
            >
              <AlertTitle>Data recency {dataRecencyAlertSeverity}</AlertTitle>
              The data in these views was last updated {daysSinceUpdate} days
              ago.
              <br />
              {dataRecencyAlertSeverity === "warning" ? (
                <>Use with caution.</>
              ) : (
                <>Do not use without express clinical consent.</>
              )}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              position: { md: "absolute" },
              left: 0,
              bottom: 0,
              ml: { md: 2.5 },
              mb: 2,
            }}
          >
            <div className="ag-unselectable">
              <Alert
                variant="outlined"
                severity={dataRecencyAlertSeverity}
                sx={{ border: "none" }}
              >
                Data correct as of <b> {FormatDate(extractDate)} </b> (
                <b>{daysSinceUpdate}</b>{" "}
                {daysSinceUpdate === 1 ? "day" : "days"} ago)
              </Alert>
            </div>
          </Box>
        </>
      )}
      {defaultSortActive || (
        <Box sx={{ position: "fixed", right: 0, bottom: 0, mr: 3, mb: 13 }}>
          <Fab
            variant="extended"
            color="primary"
            onClick={() => sortByRiskAsc()}
          >
            <UndoIcon sx={{ mr: 1 }} />
            Sort by risk
          </Fab>
        </Box>
      )}
      {selectedAppointmentNode !== null && (
        <Overlay
          offset={overlayOffset}
          onClose={onCloseOverlay}
          appointmentDetail={selectedAppointmentNode.data}
          extractDate={extractDate}
          registerUpdateWorkflowStatusCallback={
            registerUpdateWorkflowStatusInOverlayCallback
          }
        />
      )}
    </>
  );
}
